import React from 'react'
import {FaBatteryEmpty} from 'react-icons/fa'

const EmptyState = ({title,subtitle,icon}) => {
  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100%'}}>
      {icon ? icon : <FaBatteryEmpty color='grey' size={32}/>}
      <strong style={{fontSize:'20px'}}>
        {title}
      </strong>
      <span>
        {subtitle}
      </span>
    </div>
  )
}

export default EmptyState