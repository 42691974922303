import {motion} from 'framer-motion'
import React from 'react'

const OnlinePositionsLayout = ({viewBox, background, positions,selectedMachine, setSelectedMachine,zoomToElement}) => {

  const handleCircleClick = (item) => {
    setSelectedMachine(item);
    zoomToElement(item.id.toString(), 2.5, 300, 'easeOut');
  };

  const getFillColor = (item) => {
    if (item.data === null) {
      return '#d4d4d4';
    }

    return item.data.color;
  };

  return (
    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px 0px'}}>
      <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width="100%" height="auto" preserveAspectRatio="xMidYMid meet">
        <defs>
          <style>{`
            .cls-2 {font-size: 16px; fill: #fff; font-family: Arial; font-weight: 700; text-anchor: middle; alignment-baseline: central;}
          `}</style>
        </defs>
        <motion.g id='maquinas'>
          {positions?.map(item => {
            const isSelected = selectedMachine && selectedMachine.id === item.id;
            const nombreCliente =
            item.data === null ? 
            ''
            : 
            item.data.rejected  ? '❌' : item.data.text;

            return (
              <motion.g key={item.id} style={{cursor: 'pointer'}} onClick={() => handleCircleClick(item)}>
                <motion.circle
                  initial={isSelected ? { scale: 1 } : {}}
                  animate={isSelected ? { opacity: [1, 0.5, 1], scale: [1, 1.4, 1] } : {}}
                  transition={{ duration: 1, repeat: isSelected ? Infinity : 0 }}
                  id={item.id}
                  cx={item.cx}
                  cy={item.cy}
                  r="15"
                  fill={getFillColor(item)}
                />
                <motion.text id={`textm${item.id}`} x={item.cx} y={item.cy} className="cls-2">{nombreCliente}</motion.text>
              </motion.g>
            )
          })}
        </motion.g>
        {background}
      </motion.svg>
    </div>
  )
}

export default OnlinePositionsLayout
